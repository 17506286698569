.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;;
    svg {
        margin: 7px;
    }
}

.toggle-button {
    border-radius: 5px;
    background-color: #F5F5F5;
    border: 1px solid #E0E0E0;
}
.toggle-button:hover {
    cursor: pointer;
    background-color: white;
}

.sidebar-buttons {
    margin-top: 57px;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
}
.closed {
    padding: 0 0px;
}


.button-text {
    text-transform: capitalize;
    color: #1D1B1B;
    @media screen and (max-width: 850px) {
        display: none;
    }
}

.setting-button {
    position: absolute;
    bottom: 10px;
    padding: 0 30px;
}
.setting-button.closed {
    padding: 0 16px;
    margin: 0 auto;
}