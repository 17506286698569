@font-face {
	font-family: 'Apercu';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('./fonts/ApercuLight.woff2');
}

@font-face {
	font-family: 'Apercu';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/ApercuRegular.woff2');
}

@font-face {
	font-family: 'Apercu';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/ApercuMedium.woff2');
}

@font-face {
	font-family: 'Apercu';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/ApercuBold.woff2');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* DATA GRID OVERRIDES */
.MuiDataGrid-root {
  border: 0 !important;
}
.MuiDataGrid-main {
	border: 1px solid #A39C98;
	margin-top: -30px;
}
.css-1oudwrl::after {
  background-color: #A39C98 !important;
}
.MuiDataGrid-root .MuiDataGrid-row {
  --rowBorderColor: #A39C98 !important;
}
.MuiButtonBase-root-MuiButton-root {
  color: #1D4ED8 !important;
	font-weight: 600;
}
.MuiDataGrid-cell--withRightBorder, .MuiDataGrid-columnHeader--withRightBorder {
	border-right: 0 !important;
}
.MuiDataGrid-cell--withLeftBorder, .MuiDataGrid-columnHeader--withLeftBorder {
	border-left: 0 !important;
}
.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedLeft,
.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedRight {
	background: #F6FBF3 !important
}
.MuiInputBase-root-MuiInput-root {
	flex-direction: row-reverse;
}
.MuiDataGrid-toolbarQuickFilter {
	border-radius: 28px;
	background: #F7F6F6;
	padding: 8px 10px !important;
}
.MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before {
	border-bottom: 0 !important;
}
.MuiDataGrid-filler--pinnedRight {
	border-left: 0 !important;
}
.MuiDataGrid-filler--pinnedLeft {
	border-right: 0 !important	;
}
.MuiDataGrid-rowCount,
.MuiDataGrid-selectedRowCount {
	display: none !important;
}
.MuiDataGrid-withBorderColor {
	border: 0 !important;
}