.zones {
    border-bottom: 1px solid  var(--Grey-scale-Smoke, #CEC6C2);
    width: 100%;
    text-align: left;
    margin-top: 66px;
    padding-left: 48px;
}

.zone-button {
    background: none;
    border: none;
    padding: 8px 16px;
    color: #4F4C4A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0 5px;
}

.active-zone-button {
    border-bottom: #000 2px solid;
    font-weight: bold;
    color: #1D1B1B;
}

.control-button-text {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.control-button-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    font-family: 'Roboto', sans-serif;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #F8F8F4;
}
#customized-menu > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    margin-top: 8px;
}

#root > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-10.MuiGrid-grid-sm-9.css-dlh8sq-MuiGrid-root > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1paetzb-MuiGrid-root > div > div.MuiDataGrid-toolbarContainer.css-zf52lr-MuiDataGrid-toolbarContainer {
    margin-bottom: 28px;
    margin-top: 48px;
}

.css-1dz4hlz-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: #fff;
}