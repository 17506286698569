.bay-image {
    width: 100%;
    /* use css to crop this image and make it and responsive to cointainer size*/
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 5px;
}

.bay-title {
    color: #74716F;
    text-align: left;
    font-weight: normal;
    margin: 8px;
}

.active-button {
    color: #fff;
}